/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable react-hooks/rules-of-hooks */
import { useEffect, useState, Dispatch, SetStateAction } from 'react'
import { isBrowser } from '@/utilities/misc'

export function useLocalStorage<T>(
  key: string,
  fallbackValue?: T
): [T, Dispatch<SetStateAction<T>>] {
  if (!isBrowser) {
    return [fallbackValue as T, () => {}]
  }

  const [value, setValue] = useState<T>(() => {
    const stored = localStorage.getItem(key)
    const value = stored ? JSON.parse(stored) : fallbackValue

    return value
  })

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(value))
  }, [value])

  return [value, setValue]
}
